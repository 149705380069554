import {getClientSideCookie, handleGetDefaultCountry} from '@/utils'
import {captureException} from '@sentry/nextjs'
import {useQuery} from 'react-query'
import {getMenu} from '.'
import {useGetCurrentLanguage} from '@/hooks/useTranslate'
import {useEffect, useState} from 'react'

export default function useHeader({fetchData = false}: {fetchData?: boolean} = {}) {
  const language = useGetCurrentLanguage()
  const cacheKey = `menu_cache_${language}`
  const [initialData, setInitialData] = useState<any | undefined>(undefined)

  // Check for sessionStorage on the client side
  useEffect(() => {
    if (fetchData) {
      if (typeof window !== 'undefined' && sessionStorage) {
        const cachedData = sessionStorage.getItem(cacheKey)
        if (cachedData) {
          setInitialData(JSON.parse(cachedData))
        } else {
          refetchMenu()
        }
      }
    }
  }, [cacheKey])

  const {
    isFetching: isFetchingMenu,
    data: menuData,
    refetch: refetchMenu,
  } = useQuery({
    queryKey: ['menu', language],
    queryFn: async () => {
      const countryId = getClientSideCookie('country_id') || handleGetDefaultCountry().id
      const result = await getMenu({
        country_id: Number(countryId),
        language: language,
      })

      // Cache the data in sessionStorage, only if it's available (client-side)
      if (typeof window !== 'undefined' && sessionStorage) {
        sessionStorage.setItem(cacheKey, JSON.stringify(result))
      }

      return result
    },
    onError: (error) => {
      captureException(error, {
        extra: {
          message:
            'Error getting client-side menu. This error possibly occurred in the pages router part of the application.',
          action: 'getMenu',
        },
      })
    },
    enabled: false,
  })

  return {
    get: {
      isFetchingMenu,
      menuData: initialData || menuData,
    },
  }
}
