import {defaultLocale, getCountryByCountryId} from '@/i18n'
import {MenuItem, MenusService} from '@/services/menus.service'
import {getLocale} from 'next-intl/server'

export const getMenu = async ({
  country_id,
  language,
}: {
  country_id: number
  language: string
}): Promise<{primaryMenu: MenuItem[]; secondaryMenu: MenuItem[]}> => {
  const primaryMenu = await handleGetMenu(
    process.env.NEXT_PUBLIC_APP_PRIMARY_MENU_KEY || '',
    country_id,
    language
  )

  const secondaryMenu = await handleGetMenu(
    process.env.NEXT_PUBLIC_APP_SECONDARY_MENU_KEY || '',
    country_id,
    language
  )

  return {
    primaryMenu,
    secondaryMenu,
  }
}

const handleGetMenu = async (key: string, country_id: number, language: string) => {
  const menusService = new MenusService('', {
    country_id,
  })

  let menu = await menusService.getMenu(key, language)

  const locale = `${language}-${getCountryByCountryId(country_id.toString()).iso2}`

  menu = menu.map((item: MenuItem) => {
    switch (item.type) {
      case 'category': {
        return {
          ...item,
          link: `/${locale}/categories/${item.slug}-c-${item.id}`,
          subCategories: item.subCategories.map((subItem) => {
            return {
              ...subItem,
              link: `/${locale}/categories/${subItem.name.toLowerCase().replaceAll(' ', '-')}-c-${
                subItem.id
              }`,
            }
          }),
        }
      }
      case 'page': {
        return {
          ...item,
          link: `/${locale}/pages/${item.slug}`,
        }
      }
      case 'static': {
        return item
      }
    }
  })

  return menu
}

export * as C from './components'
