export const languages = [
  {
    lang: 'tr',
    name: 'Türkçe',
    flag: '/v2/Assets/Icons/Turkey.svg',
  },
  {
    lang: 'en',
    name: 'English',
    flag: '/v2/Assets/Icons/USA.svg',
  },
]

export const initialCountry = {
  icon: '',
  id: null,
  name: '',
}

export const initialCurrency = {
  icon: '',
  id: null,
  name: '',
  sign: '',
}

export const initialLanguage = {
  lang: 'tr',
  name: 'Türkçe',
  flag: '/v2/Assets/Icons/Turkey.svg',
}
