'use client'

import SearchSuggestion from '@/components/search-suggestion'
import {useGetCurrentLanguage, useTranslate} from '@/hooks/useTranslate'
import {SearchSuggestionItem} from '@/services/lib/types/productSearch.type'
import {ProductsSearchService} from '@/services/products-search.service'
import {useGlobalStore} from '@/store'
import {getClientSideCookie, RenderIf} from '@/utils'
import {apiRoutes} from '@/utils/apiRoutes'
import Image from 'next/image'
import {useParams, useRouter} from 'next/navigation'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {useShallow} from 'zustand/react/shallow'
import styles from './styles.module.scss'
import {captureException} from '@sentry/nextjs'
import {getCountryByCountryId} from '@/i18n'

const SEACH_BOUNCE_TIME = 500

const Search = () => {
  const {userSetting, handleUpdateSearchText} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      handleUpdateSearchText: state.handleUpdateSearchText,
    }))
  )
  const language = useGetCurrentLanguage()
  const router = useRouter()
  const params = useParams<{locale: string}>()
  const t = useTranslate

  const [searchResult, setSearchResult] = useState<null | SearchSuggestionItem[]>(null)
  const [searchInputValue, setSearchInputValue] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const searchTimeoutId = useRef<NodeJS.Timeout | null>(null)
  const searchSuggestionWrapperRef = useRef<HTMLDivElement>(null)
  const searchInputWrapperRef = useRef<HTMLDivElement>(null)
  const [abortController, setAbortController] = useState<AbortController | null>(null)

  /**
   * Memoized locale value derived from URL params or cookies.
   *
   * @returns {string} The locale string in format 'language-countryCode' or empty string
   * @description
   * - First tries to get locale from URL params
   * - If not found, attempts to construct locale from country_id and language cookies
   * - Returns empty string if cookies are missing or error occurs, triggering middleware redirect
   */
  const locale = useMemo(() => {
    try {
      if (params?.locale) return params?.locale

      const countryId = getClientSideCookie('country_id') || ''
      const language = getClientSideCookie('language') || ''

      if (!countryId || !language) return '' // Passing empty locale, would couse to redirect to correct locale from the middleware.

      return `${language}-${getCountryByCountryId(countryId).iso2}`
    } catch (error) {
      return '' // Passing empty locale, would couse to redirect to correct locale from the middleware.
    }
  }, [])

  /**
   * Handles navigation to the search results page.
   * If a search input value exists, it updates the stored search text
   * and navigates to the search results page with the query parameter.
   */
  const handleNavigateToSearch = () => {
    if (!!searchInputValue) {
      handleUpdateSearchText(searchInputValue)

      setSearchInputValue('')
      setSearchResult(null)

      router.push(`/${locale}/search${apiRoutes.PRODUCT_LIST}?q=${searchInputValue}`)
    }
  }

  /**
   * Handles the submission of a search query.
   *
   * @param {string} value - The search query value.
   * @description This function manages the search input value and triggers a search after a delay.
   * It clears any existing timeout, updates the search input value, and sets a new timeout to
   * perform the search after some milliseconds.
   */
  const handleSubmitSearch = (value: string) => {
    if (searchTimeoutId.current) clearTimeout(searchTimeoutId.current)

    if (abortController) abortController.abort()
    setAbortController(new AbortController())
    setSearchInputValue(value)

    searchTimeoutId.current = setTimeout(() => {
      handleSearch()
    }, SEACH_BOUNCE_TIME)
  }

  /**
   * Creates a memoized instance of ProductsSearchService.
   */
  const productSearchService = useMemo(() => {
    return new ProductsSearchService('', {
      country_id: userSetting.country_id,
    })
  }, [userSetting.country_id])

  const {refetch: handleSearch, isFetching} = useQuery({
    queryFn: async () => {
      if (!searchInputValue) {
        setSearchResult(null)
        return
      }

      const response = await productSearchService.searchSuggestions(
        language,
        searchInputValue,
        abortController || undefined
      )
      setSearchResult(response.data.result)
      return
    },
    enabled: false,
    onError: (error) => {
      captureException(error)
    },
  })

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchInputWrapperRef.current &&
      !searchInputWrapperRef.current.contains(event.target as Node) &&
      searchSuggestionWrapperRef.current &&
      !searchSuggestionWrapperRef.current.contains(event.target as Node)
    ) {
      setSearchInputValue('')
      setSearchResult(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles['main-header__search']}>
      <div className={styles['main-header__search__input-wrapper']} ref={searchInputWrapperRef}>
        {/* Input field for search queries */}
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleNavigateToSearch()
          }}
          placeholder={t('SEARCH')}
          className={styles['main-header__search-input']}
          data-testid='desktop-search-input'
          onChange={(e) => {
            handleSubmitSearch(e.target.value)
          }}
          value={searchInputValue}
          ref={searchInputRef}
        />

        {/* Search button */}
        <button className={styles['main-header__search-button']} onClick={handleNavigateToSearch}>
          <span className={styles['main-header__search-button-icon']}>
            {/* Search icon */}
            <Image src='/v2/Assets/Images/header/search.svg' alt='' width={15} height={15} />
          </span>
          Ara
        </button>
      </div>

      <RenderIf isTrue={(!!searchInputValue && isFetching) || !!searchResult || isFetching}>
        <div
          className={styles['main-header__search__result-wrapper']}
          ref={searchSuggestionWrapperRef}
        >
          {/* SearchSuggestion component to display search results */}
          <SearchSuggestion
            items={searchResult}
            isLoading={isFetching}
            searchTerm={searchInputValue}
            isMobile={false}
            handleClickItem={() => {
              setSearchInputValue('')
              setSearchResult(null)
            }}
          />
        </div>
      </RenderIf>
    </div>
  )
}

export default Search
