import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'
import {usePathname} from 'next/navigation'
import {useMemo} from 'react'

export const useFooter = () => {
  const isMobile = useCustomMediaQuery(573)
  const pathname = usePathname()

  const handleMobileExtraStyle = () => {
    if (pathname === '/cart') {
      return {
        paddingBottom: 80,
      }
    } else if (pathname === '/payment' || pathname === '/payment/success') {
      return {
        paddingBottom: 74,
      }
    } else if (pathname && pathname.includes('/products')) {
      return {
        paddingBottom: 74,
      }
    } else {
      return {
        paddingBottom: 30,
      }
    }
  }

  const bottomLinks = useMemo(
    () => [
      {
        name: 'ABOUT_US',
        href: '/about',
      },
      {
        name: 'DELIVERY_CONDITIONS',
        href: '/delivery-terms',
      },
      {
        name: 'USER_AGREEMENT',
        href: '/user-agreement',
      },
      {
        name: 'SALE_AGREEMENT',
        href: '/sale-agreement',
      },
      {
        name: 'PRIVACY_POLICY',
        href: '/privacy-policy',
      },
    ],
    []
  )

  const pagesWithoutFooter = useMemo(() => [], [])

  const currentYear = useMemo(() => new Date().toLocaleString('en-US', {year: 'numeric'}), [])

  const renderFooter = (pathname: string) =>
    pagesWithoutFooter.some((route) => pathname.includes(route))

  const shouldNotRenderFooter = renderFooter(pathname as string)

  return {
    get: {
      isMobile,
      shouldNotRenderFooter,
      pathname,
      currentYear,
      bottomLinks,
    },
    on: {
      handleMobileExtraStyle,
    },
  }
}
