'use client'

import {useEffect, useMemo, useState} from 'react'
import {useTranslate} from '@/hooks/useTranslate'
import {handleCheckAuthStatus} from '@/utils/authHelper'

// components
import {RenderIf} from '@/utils'
import Image from 'next/image'
import Link from 'next/link'

// styles
import styles from './styles.module.scss'

interface Props {
  isMobile?: boolean
}

export default ({isMobile = false}: Props) => {
  const t = useTranslate
  const {isLogin} = handleCheckAuthStatus()
  const href = useMemo(() => (isLogin ? '/my-account' : '/auth/login'), [isLogin])
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  return (
    <Link href={href} data-testid='account-page-link'>
      <div
        className={
          isMobile
            ? styles['main-header__user-profile-link__mobile']
            : styles['main-header__user-profile-link']
        }
      >
        <Image
          src='/v2/Assets/Images/header/user-profile.svg'
          alt={isLogin ? t('LOGGED_IN_PROFILE') : t('PROFILE')}
          width={24}
          height={24}
        />
        <RenderIf isTrue={isRendered && !isMobile}>
          <span>{isLogin ? t('LOGGED_IN_PROFILE') : t('PROFILE')}</span>
        </RenderIf>
      </div>
    </Link>
  )
}
