'use client'

import clsx from 'clsx'
import {useState} from 'react'

// components
import {RenderIf} from '@/utils'
import Link from 'next/link'
import {ArrowDownLight, ArrowUpActive, PolygonUp} from './icons'

// styles
import styles from './styles.module.scss'
import {MenuItem} from '@/services/menus.service'
import MenuPlaceHolder from './placeholder'
import useHeader from '../../../useHeader'

export default function Menu({items}: {items?: MenuItem[]}) {
  const {get} = useHeader({fetchData: !items})

  // states
  const [subCategoriesContainer, setSubCategoriesContainer] = useState({
    show: false,
    id: null,
  })

  /**
   * Handles showing subcategories on hover
   * @param {any} id - ID of the menu item
   * @param {any} subCategories - Subcategories of the menu item
   */
  const showSubCategoriesHandler = (id: any, subCategories: any) => {
    if (subCategories !== undefined && subCategories.length > 0) {
      setSubCategoriesContainer({
        show: true,
        id: id,
      })
    } else {
      setSubCategoriesContainer({
        show: false,
        id: id,
      })
    }
  }

  /**
   * Renders a single menu item
   * @param {MenuItem} item - Menu item to render
   * @returns {JSX.Element} Rendered menu item
   */
  const handleRenderMenuItem = (item: MenuItem) => {
    return (
      <div key={item.id} className={styles['header-categories__item']}>
        <Link
          href={item.link}
          className='header__link--desktop-item'
          data-testid='header-category-item'
        >
          <div
            onMouseEnter={() => showSubCategoriesHandler(item.id, item.subCategories)}
            onMouseLeave={() =>
              item.subCategories?.length > 0
                ? setSubCategoriesContainer((prevState) => {
                    return {...prevState, show: false}
                  })
                : setSubCategoriesContainer({
                    show: false,
                    id: null,
                  })
            }
            className={clsx(styles['header-categories__item-title'], {
              [styles['header-categories__item-title-active']]:
                subCategoriesContainer.id === item.id,
            })}
          >
            <span>{item.name}</span>
            <RenderIf isTrue={item.subCategories.length > 0}>
              {subCategoriesContainer.id === item.id ? <ArrowUpActive /> : <ArrowDownLight />}
            </RenderIf>
          </div>

          {subCategoriesContainer.id === item.id && subCategoriesContainer.show && (
            <span className={styles['header__link--desktop-item__icon']}>
              <PolygonUp />
            </span>
          )}
        </Link>
        <div className={styles['header-categories__item__sub-items']}>
          {/* NOTE: displaying sub-categories should be SSR */}
          {/* NOTE: displaying sub-categories should be SSR */}
          {/* NOTE: displaying sub-categories should be SSR */}
          {subCategoriesContainer.id === item.id && subCategoriesContainer.show && (
            <div
              onMouseEnter={() =>
                setSubCategoriesContainer((prevState) => {
                  return {...prevState, show: true}
                })
              }
              onMouseLeave={() =>
                setSubCategoriesContainer({
                  show: false,
                  id: null,
                })
              }
              className={styles['header-categories__item__sub-items__wrapper']}
            >
              <div className={styles['header-categories__item__sub-items__wrapper__item']}>
                {item.subCategories?.map((item: any) => (
                  <Link
                    key={item.id}
                    href={item.link}
                    className='header__link--desktop-item__sub-item'
                  >
                    <span
                      className={styles['header-categories__item__sub-items__wrapper__item__title']}
                    >
                      {item.name}
                    </span>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return items || get.menuData ? (
    <div className={styles['header-categories']}>
      {(items || get.menuData.primaryMenu).map((item: MenuItem) => handleRenderMenuItem(item))}
    </div>
  ) : get.isFetchingMenu || !get.menuData ? (
    <MenuPlaceHolder />
  ) : (
    <div className={styles['header-categories']}></div>
  )
}
