export const PolygonUp = ({color = 'white'}: {color?: string}) => (
  <svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5 0L10 7H0L5 0Z' fill={color} />
  </svg>
)

export const ArrowDownLight = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6.5' height='3.5' viewBox='0 0 8 5' fill='none'>
    <path
      d='M7 0.939453L3.74997 4.50008L0.5 0.939453'
      stroke='#BDBDBD'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ArrowUpActive = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='6.5' height='3.5' viewBox='0 0 8 5' fill='none'>
    <path
      d='M0.500122 4.5L3.75015 0.939369L7.00012 4.5'
      stroke='#E26C2D'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
