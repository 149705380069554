'use client'

import dynamic from 'next/dynamic'

// components
import {Fragment} from 'react'
import Logo from '../../logo'
import Search from './search'
import UserProfileLink from '../../header-actions/user-profile-link'
import FavoriteLink from '../../header-actions/favorite-link'

// styles
import styles from './styles.module.scss'
import CartLinkPlaceholder from '../../header-actions/cart-link/placeholder'

const CartLink = dynamic(() => import('../../header-actions/cart-link'), {
  ssr: false,
  loading: () => <CartLinkPlaceholder isMobile={false} />,
})

export default function MainHeader() {
  return (
    <Fragment>
      <div className={styles['main-header']}>
        {/* logo */}
        <div className={styles['main-header__logo']}>
          <Logo width={118} height={40} />
        </div>

        {/* search */}
        <div className={styles['main-header__search-container']}>
          <Search />
        </div>

        {/* actions */}
        <div className={styles['main-header__links']}>
          <UserProfileLink />
          <FavoriteLink />
          <CartLink />
        </div>
      </div>
    </Fragment>
  )
}
