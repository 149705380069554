import {Country} from '@/types/Country'
import {Currency} from '@/types/Currency'
import {Language} from '@/types/Language'
import {UserSettings} from '@/types/UserSettings'

export enum children {
  languages = 'languages',
  cuntries = 'cuntries',
  currencies = 'currencies',
}

export type Children = children.cuntries | children.currencies | children.languages

export type CountryType = Country
export type CurrencyType = Currency
export type LanguageType = Language

export type UserSettingsType = UserSettings
