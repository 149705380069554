'use client'

import {useTranslations} from 'next-intl'
import {QueryClient, QueryCache, QueryClientProvider} from 'react-query'
import toast from 'react-hot-toast'
import {captureException} from '@sentry/nextjs'
import {isAxiosError} from 'axios'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any) => {
      if (['Network Error', 'canceled'].includes(error.message)) return
      else if (error.name === 'AbortError') {
        captureException(error, {
          extra: {
            message: 'AbortError in QueryClient',
            errorName: error.name,
            errorMessage: error.message,
            errorStack: error.stack,
            request: isAxiosError(error) ? error.request : null,
          },
        })

        return
      }

      const statusCode = error?.response?.status
      if ([403, 404, 422].includes(statusCode)) return
      else if (statusCode >= 500) {
        const t = useTranslations()
        toast.error(t('SOMETHING_WENT_WRONG'))
        return
      } else {
        captureException(error, {
          extra: {
            message: 'Error in QueryClient: Error 500 in QueryClient',
            statusCode,
            errorMessage: error.message,
            errorStack: error.stack,
          },
        })
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (error?.response?.status >= 500 && error?.response?.status <= 599) {
          if (failureCount >= 1) return false
          return true
        }
        return false
      },
      retryDelay: 3000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
})

export const QueryClientComponent = ({children}: {children: React.ReactNode}) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
