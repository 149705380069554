'use client'

import {useGetCurrentLanguage, useTranslate} from '@/hooks/useTranslate'
import {useEffect, useState} from 'react'
import {useShallow} from 'zustand/react/shallow'
import {useGlobalStore} from '@/store'
import {handleCheckAuthStatus} from '@/utils/authHelper'

export const useUserSettings = () => {
  const {userSetting, handleToggleUserSettingModal} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      handleToggleUserSettingModal: state.handleToggleUserSettingModal,
    }))
  )

  const t = useTranslate
  const [isClient, setIsClient] = useState(false)

  const {isLogin} = handleCheckAuthStatus()

  const currentLanguage = useGetCurrentLanguage()

  const handleClick = () => {
    handleToggleUserSettingModal(!userSetting.showUserSettingModal)
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  return {
    get: {
      showUserSettingModal: userSetting.showUserSettingModal,
      t,
      lang: currentLanguage,
      isLogin,
      currentLanguage,
      currentCurrency: userSetting?.currencyName,
      currentCountryId: userSetting?.country_id,
      getUserSettingLoading: userSetting?.getUserSettingLoading,
      isClient,
    },
    on: {
      handleClick,
    },
  }
}
