'use client'

import {getCountryByCountryId} from '@/i18n'
import {Fragment} from 'react'
import dynamic from 'next/dynamic'
import * as Lib from './lib'

const UserSettingModal = dynamic(
  () =>
    import('@/components/user-setting-modal/UserSettingModal').then((mod) => mod.UserSettingModal),
  {
    ssr: false,
  }
)

const UserSettings = () => {
  const {get, on} = Lib.H.useUserSettings()

  return (
    <div style={{flex: '0.1'}}>
      {get.isClient && !get.getUserSettingLoading ? (
        <Fragment>
          <Lib.C.HeaderSelectButton
            handleClick={on.handleClick}
            currentCountryISO2={getCountryByCountryId(
              get.currentCountryId.toString()
            )?.iso2.toUpperCase()}
          />

          <UserSettingModal router='appRouter' />
        </Fragment>
      ) : (
        <Lib.C.PlaceHolder />
      )}
    </div>
  )
}

export default UserSettings
