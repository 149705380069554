const apiUrl = process.env.NEXT_PUBLIC_BASE_URL
const searchUrl = process.env.NEXT_PUBLIC_SEARCH_URL

export const baseUrl = apiUrl
export const searchServiceUrl = searchUrl
export const Url = 'https://auth.tofisagiyim.com'
export const imageUrl = `${Url}/images`

export const apiRoutes = {
  LOGIN: '/login',
  COLORS: '/colors',
  SIZE: '/variations',
  ADDRESS: '/addresses',
  REGISTER: '/register',
  FAVORITE: '/favorites',
  USER_DETAIL: '/detail',
  NEWPASS: '/new-password',
  FASTLOGIN: '/fast-login',
  PRODUCT_LIST: '/products',
  IMAGE_BATCH: '/media/batch',
  FAST_VERIFY: '/fast-verify',
  RESETPASS: '/reset-password',
  CATEGORIES_LIST: '/categories',
  COUNTRY: '/addresses/country',
  CHANGE_PASSWORD: '/change-password',
  DISTRICT: '/addresses/district',
  PROVINCE: '/addresses/province',
  REGISTER_VERIFY_CODE: '/verify-code',
  GOOGLE_LOGIN: '/login/google/redirect',
  RESETPSS_VERIFY_CODE: '/verify-code-reset',
  NEIGHBOURHOOD: '/addresses/neighborhood',
  PHONE_VERIFY_CODE: '/verify-phone/send-code',
  PHONE_SUBMIT_CODE: '/verify-phone/confirm',
  PAGES: '/pages',
  CARD: '/cards',
  ORDERS: '/orders',
  REVIEW: '/reviews',
  CLEAR_CARD: '/cards/clear',
}
