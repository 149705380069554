import {RectShape} from 'react-placeholder/lib/placeholders'

export const PlaceHolder = () => {
  return (
    <div style={{width: 175, display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
      <RectShape color='#E6E6E6' style={{width: 15, height: 15, borderRadius: '50%'}} />
      <RectShape color='#E6E6E6' style={{width: '50%', height: 12.5, borderRadius: '15px'}} />
    </div>
  )
}
