import {SearchSuggestionItem} from '@/services/lib/types/productSearch.type'
import {RenderIf} from '@/utils'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Link from 'next/link'
import * as Lib from './lib'

export default function SearchSuggestion({
  items,
  isLoading,
  searchTerm,
  isMobile,
  handleClickItem,
}: {
  items: SearchSuggestionItem[] | null
  isLoading: boolean
  searchTerm: string
  isMobile: boolean
  handleClickItem?: () => void
}) {
  const {get, on} = Lib.H.useSearchSuggestion(items, searchTerm)

  return (
    <>
      <UI_Typography
        variant='Medium/Med14'
        className={clsx({
          [styles['search-text']]: true,
          [styles['search-text--mobile']]: isMobile,
        })}
      >
        {get.t('RELATED_RESULTS')}
      </UI_Typography>

      {/* search result list */}
      <div
        className={clsx({
          [styles['search-results']]: true,
          [styles['search-results--mobile']]: isMobile,
        })}
      >
        {/* loading */}
        <RenderIf isTrue={isLoading}>
          <div className={styles['search-results__loading']}>
            <Lib.I.Loading />
          </div>
        </RenderIf>

        {/* empty result message */}
        <RenderIf isTrue={!isLoading && items !== null && items.length === 0}>
          <div className={styles['search-results__empty-message']}>{get.emptyResultMessage()}</div>
        </RenderIf>

        {!isLoading &&
          items &&
          items.map((data) => (
            <Link
              href={`/${data.url}`}
              className={clsx({
                [styles['search-results__item']]: true,
              })}
              key={data.url}
              onClick={() => {
                handleClickItem && handleClickItem()
              }}
            >
              <Lib.I.Magnifier />

              <div className={styles['search-results__item__title']}>
                <on.handleBoldWords sentence={data.title} />
              </div>
              <UI_Typography
                variant='Regular/Reg10'
                className={styles['search-results__item__type']}
                component={'p'}
              >
                {data.label}
              </UI_Typography>
            </Link>
          ))}
      </div>
    </>
  )
}
