import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {useTranslate} from '@/hooks/useTranslate'
import {SearchSuggestionItem} from '@/services/lib/types/productSearch.type'
import {useCallback} from 'react'

export const useSearchSuggestion = (items: SearchSuggestionItem[] | null, searchTerm: string) => {
  const t = useTranslate

  /**
   * Bolds specific words within a given sentence.
   *
   * @param {Object} data - The input data object.
   * @param {string} data.sentence - The sentence to process.
   * @param {string[]} data.wordsToBold - An array of words to be bolded in the sentence.
   * @returns {JSX.Element} A Typography component with bolded words.
   */
  const handleBoldWords = useCallback(
    (data: {sentence: string}) => {
      const wordsToBold = [searchTerm.toLowerCase()]
      const regex = new RegExp(`(${wordsToBold.join('|')})`, 'gi')

      const parts = data.sentence.split(regex).filter((i) => i)

      return (
        <UI_Typography variant='Regular/Reg12' component={'p'}>
          {parts.map((part, index) =>
            wordsToBold.includes(part.toLowerCase()) ? (
              <UI_Typography variant='Medium/Med12' component={'span'} key={index}>
                {part}
              </UI_Typography>
            ) : (
              part
            )
          )}
        </UI_Typography>
      )
    },
    [items]
  )

  const emptyResultMessage = useCallback(() => {
    return (
      <>
        <UI_Typography variant='Regular/Reg12'>"{searchTerm}"</UI_Typography>
        <UI_Typography variant='Regular/Reg12'>{t('NO_RESULT_FOUND')}</UI_Typography>
      </>
    )
  }, [items])

  return {
    get: {
      t,
      emptyResultMessage,
    },
    on: {
      handleBoldWords,
    },
  }
}
