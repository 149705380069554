'use client'

import {useState} from 'react'
import {useGlobalStore} from '@/store'
import {useShallow} from 'zustand/react/shallow'
import {MenuItem} from '@/services/menus.service'
import dynamic from 'next/dynamic'
import UserProfileLink from '../header-actions/user-profile-link'
import FavoriteLink from '../header-actions/favorite-link'

// components
const Logo = dynamic(() => import('../logo'))
const MobileSidebar = dynamic(() => import('../mobile-sidebar'), {ssr: false})
const Menu = dynamic(() => import('./menu'))
const MenuButton = dynamic(() => import('./menu-button'))
const SearchButton = dynamic(() => import('./search-button'))
const CartLink = dynamic(
  () => import('@/components/header/_lib/components/header-actions/cart-link'),
  {
    ssr: false,
    loading: () => <CartLinkPlaceholder isMobile={true} />,
  }
)

// styles
import styles from './styles.module.scss'
import CartLinkPlaceholder from '../header-actions/cart-link/placeholder'
import useHeader from '../../useHeader'

const MobileHeader = ({
  primaryMenuItems,
  secondaryMenuItems,
}: {
  primaryMenuItems?: MenuItem[]
  secondaryMenuItems?: MenuItem[]
}) => {
  const {get} = useHeader({fetchData: !primaryMenuItems || !secondaryMenuItems})
  const {mobileSidebarIsOpen, handleUpdateMobileSidebarIsOpen} = useGlobalStore(
    useShallow((state) => ({
      mobileSidebarIsOpen: state.mobileSidebarIsOpen,
      handleUpdateMobileSidebarIsOpen: state.handleUpdateMobileSidebarIsOpen,
    }))
  )

  // states
  const [enableMenuSearchMode, setEnableMenuSearchMode] = useState(false)

  return (
    <div className={styles['mobile-header']}>
      <div className={styles['main-header']} data-testid='main-mobile-header'>
        <div className={styles['main-header__left']}>
          <MenuButton onClick={() => handleUpdateMobileSidebarIsOpen(true)} />
          <Logo width={93} height={35} />
        </div>

        <div className={styles['main-header__right']}>
          <UserProfileLink isMobile={true} />
          <FavoriteLink isMobile={true} />
          <CartLink isMobile={true} />
        </div>
      </div>

      <div
        className={styles['main-header__search-container']}
        data-testid='header-mobile-search-button'
      >
        <SearchButton
          onClick={() => {
            handleUpdateMobileSidebarIsOpen(true)
            setEnableMenuSearchMode(true)
          }}
        />
      </div>

      <Menu items={primaryMenuItems || get.menuData?.primaryMenu} isFetching={get.isFetchingMenu} />

      <MobileSidebar
        isOpen={mobileSidebarIsOpen}
        searchMode={enableMenuSearchMode}
        handleClose={() => {
          handleUpdateMobileSidebarIsOpen(false)
          setEnableMenuSearchMode(false)
        }}
        primaryMenuItems={primaryMenuItems || get.menuData?.primaryMenu}
        secondaryMenuItems={secondaryMenuItems || get.menuData?.primaryMenu}
      />
    </div>
  )
}

export default MobileHeader
