'use client'

import {isMobileDevice} from '@/app/_lib/csr-helpers'
import {setLanguage as setLanguageAppRouter} from '@/i18n'
import {setLanguage as setLanguagePagesRouter, useLang} from '@/i18n/i18n'
import {CartsService} from '@/services/carts.service'
import {CategoriesService} from '@/services/categories.service'
import {FavoritesService} from '@/services/favorites.service'
import {UserSettingsService} from '@/services/user-settings.service'
import {useGlobalStore} from '@/store'
import {setClientSideCookie} from '@/utils'
import {handleCheckAuthStatus, handleGetUUID} from '@/utils/authHelper'
import {captureException} from '@sentry/nextjs'
import {useLocale} from 'next-intl'
import {useEffect} from 'react'
import {useQuery} from 'react-query'
import {useShallow} from 'zustand/react/shallow'

export const CompositionRootProvider = ({router}: {router: 'appRouter' | 'pagesRouter'}) => {
  const {
    userSetting,
    handleUpdateUserSetting,
    handleUpdateFavorites,
    handleUpdateCart,
    handleUpdateCategories,
    handleUpdateMobileSidebarIsOpen,
  } = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      handleUpdateUserSetting: state.handleUpdateUserSetting,
      handleUpdateFavorites: state.handleUpdateFavorites,
      favorites: state.favorites,
      handleUpdateCart: state.handleUpdateCart,
      handleUpdateCategories: state.handleUpdateCategories,
      handleUpdateMobileSidebarIsOpen: state.handleUpdateMobileSidebarIsOpen,
    }))
  )
  const isMobile = isMobileDevice()
  const lang = router === 'appRouter' ? useLocale().split('-')[0] : useLang()
  const {token, isLogin} = handleCheckAuthStatus()
  const uuid = handleGetUUID()

  // Services
  const userSettingsService = new UserSettingsService(token, {
    country_id: userSetting.country_id,
  })

  const favoritesService = new FavoritesService(token, {
    country_id: userSetting.country_id,
  })

  const cartsService = new CartsService(token, {
    country_id: userSetting.country_id,
  })

  useQuery({
    queryFn: () => userSettingsService.getUserSettings(isLogin, uuid),
    queryKey: ['user-settings'],
    onSuccess: (res) => {
      const {result} = res?.data

      if (result.language !== undefined && result.language !== lang) {
        router === 'appRouter'
          ? setLanguageAppRouter(result.language, result.country_id.toString(), false)
          : setLanguagePagesRouter(result.language)
      }

      setClientSideCookie('countryId', result.country_id)
      setClientSideCookie('language', result.language)

      handleUpdateUserSetting({
        country_id: +result.country_id,
        language: result.language,
        country_name: result.country.name,
        getUserSettingLoading: false,
        currencyName: result.currency.name,
        countryFlagUrl: result.country.flag_url,
        phoneCode: +result.country.phone_code,
        iso3: result.country.iso3,
      })
    },
  })

  useQuery({
    queryKey: ['favorite-items'],
    queryFn: () => favoritesService.getFavoritesList(isLogin),
    enabled: !!uuid || isLogin,
    onSuccess: (res) => {
      const {result} = res?.data
      handleUpdateFavorites({
        user_id: result.user_id,
        products: result.products,
      })
    },
  })

  useQuery({
    queryFn: () => cartsService.getCartsLength(isLogin, uuid),
    queryKey: ['carts-length'],
    enabled: isLogin || !!uuid,
    onSuccess: (res) => {
      const {count} = res?.data?.result

      handleUpdateCart(count)
    },
    onError: () => {
      handleUpdateCart(0)
    },
  })

  useQuery({
    queryFn: async () => {
      const categoriesService = new CategoriesService(token, {
        country_id: userSetting.country_id,
      })
      return await categoriesService.getCategoryList(lang)
    },
    queryKey: ['categories-items'],
    onSuccess: (res) => {
      const {data: categoriesResult} = res?.data.result

      handleUpdateCategories(categoriesResult)
    },
    onError: (err: any) => {
      captureException(err)
    },
  })

  useEffect(() => {
    if (isMobile) {
      const handler = () => {
        handleUpdateMobileSidebarIsOpen(false)
      }

      window.addEventListener('popstate', handler)
      return () => {
        window.removeEventListener('popstate', handler)
      }
    }
  }, [])

  return null
}
