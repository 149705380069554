import {Category} from '@/types'
import * as Lib from './lib'
import {handleGetDefaultCountry} from '@/utils/helpers'

export class CategoriesService {
  private apiCaller

  private endpoint = '/categories'

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: true,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(handleGetDefaultCountry().id),
      },
    })
  }
  async getCategoryList(language: string): Promise<Lib.T.ApiCaller.Response<{data: Category[]}>> {
    return await this.apiCaller.get(`/${language}${this.endpoint}`)
  }

  async getCategoryItem(language: string, id: number): Promise<Lib.T.ApiCaller.Response<any>> {
    return await this.apiCaller.get(`/${language}${this.endpoint}/${id}`)
  }

  async getCategoryListWithFetch(language: string): Promise<Lib.T.FetchWrapper.FetchResponse<any>> {
    return await Lib.Helper.FetchWrapper({
      url: `/${language}${this.endpoint}`,
      isSearchService: true,
      options: {
        next: {
          revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_CATEGORY_LIST),
          tags: ['category-list'],
        },
      },
    })
  }

  async getRelatedCategoryListWithFetch(
    language: string,
    categoryId: number
  ): Promise<Lib.T.FetchWrapper.FetchResponse<Category[]>> {
    return await Lib.Helper.FetchWrapper({
      url: `/${language}${this.endpoint}/${categoryId}/related-categories`,
      isSearchService: true,
      options: {
        next: {
          revalidate: Number(process.env.NEXT_PUBLIC_APP_CACHE_REVALIDATE_TIME_CATEGORY_LIST),
          tags: ['related-category-list'],
        },
      },
    })
  }
}
