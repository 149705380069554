import {captureException} from '@sentry/nextjs'
import * as Lib from './lib'
import {handleGetDefaultCountry} from '@/utils/helpers'

export type MenuItem = {
  name: string
  slug: string
  id: number
  link: string // this line will be filled later
  type: 'category' | 'page' | 'static'
  imageUrl: string
  subCategories: MenuItem[]
}

export class MenusService {
  private apiCaller

  private endpoint = 'menus'

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: false,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(handleGetDefaultCountry().id),
      },
    })
  }

  // NOTE: The below method (getMenu) should be removed because we are using the built-in fetch API. Instead, you should use getMenuWithFetchAPI.
  // NOTE: The below method (getMenu) should be removed because we are using the built-in fetch API. Instead, you should use getMenuWithFetchAPI.
  // NOTE: The below method (getMenu) should be removed because we are using the built-in fetch API. Instead, you should use getMenuWithFetchAPI.
  async getMenu(menuId: string, locale: string): Promise<MenuItem[]> {
    try {
      const result = await this.apiCaller.get(`${this.endpoint}/render/${menuId}`)

      return result.data.result.items.map((item: any) => {
        const current =
          item.contents.find((i: any) => i.language === locale.split('-')[0]) || item.contents[0]

        switch (item.type) {
          case 'category': {
            return {
              name: current.name,
              slug: current.slug,
              id: item.object_id,
              link: '', // this line will be filled later
              imageUrl: item.image_url,
              type: item.type,
              subCategories: item.sub_items.map((subitem: any) => {
                const subCurrent =
                  subitem.contents.find((i: any) => i.language === locale.split('-')[0]) ||
                  subitem.contents[0]

                return {
                  type: subitem.type || item.type,
                  name: subCurrent.name,
                  id: subitem.object_id,
                }
              }),
            }
          }
          case 'page': {
            return {
              name: current.name,
              slug: current.slug,
              id: current.id,
              link: '',
              imageUrl: item.image_url,
              type: item.type,
              subCategories: [],
            }
          }
          case 'static': {
            return {
              name: current.name,
              slug: current.slug,
              id: current.id,
              link: current.slug, // currently the slug contains the link of the statis items.
              imageUrl: item.image_url,
              type: item.type,
              subCategories: [],
            }
          }
        }
      })
    } catch (e) {
      captureException(e)
      return []
    }
  }
}
