import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import clsx from 'clsx'
import Image from 'next/image'
import css from './styles.module.scss'

export const HeaderSelectButton = ({
  handleClick,
  currentCountryISO2,
}: {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  currentCountryISO2: string | undefined
}) => {
  return (
    <button type='button' className={clsx(css['headerSelectButton__button'])} onClick={handleClick}>
      <UI_Typography variant='Regular/Reg12'>{currentCountryISO2}</UI_Typography>
      <Image
        src={
          currentCountryISO2 === 'DE'
            ? '/v2/Assets/Icons/Germany.svg'
            : '/v2/Assets/Icons/Turkey.svg'
        }
        style={{
          objectFit: 'contain',
        }}
        alt=''
        width={23}
        height={17}
      />
    </button>
  )
}
